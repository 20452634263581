<template>
  <tr :class="{ darkened: index % 2 === 1 }">
    <td @click="openUser" class="align-middle cursor-pointer text-primary">
      {{ report.user.username }}
    </td>
    <td class="align-middle">{{ report.period.replace("_", ".") }}</td>
    <td class="align-middle">{{ $t(userType) }}</td>
    <td class="align-middle">{{ report.profit_percent }}%</td>
    <td class="align-middle">
      <div class="d-inline-flex small w-100">
        <div class="position-relative d-flex">
          <span class="d-inline-block w-50">
            {{ report.evangelist_sum }}
          </span>
          <span class="d-inline-block w-50">
            {{ report.producer_sum }}
          </span>
        </div>
        <div class="position-relative d-flex">
          <span class="d-inline-block w-50">
            {{ report.content_ad_sum }}
          </span>
          <span class="d-inline-block w-50">
            {{ report.public_ad_sum }}
          </span>
        </div>
      </div>
    </td>
    <td class="align-middle">{{ report.total_sum }}</td>
    <td class="align-middle"></td>
  </tr>
</template>

<script>
export default {
  props: {
    report: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  computed: {
    userType() {
      if (this.report.user.is_producer) {
        return "producer";
      }
      return "user";
    }
  },
  methods: {
    openUser() {
      this.$router.push({
        name: "User",
        params: { id: this.report.user.idt_user }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/_variables.scss";

.darkened {
  background: $light;
}
</style>
