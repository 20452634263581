var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", { class: { darkened: _vm.index % 2 === 1 } }, [
    _c(
      "td",
      {
        staticClass: "align-middle cursor-pointer text-primary",
        on: { click: _vm.openUser }
      },
      [_vm._v("\n    " + _vm._s(_vm.report.user.username) + "\n  ")]
    ),
    _vm._v(" "),
    _c("td", { staticClass: "align-middle" }, [
      _vm._v(_vm._s(_vm.report.period.replace("_", ".")))
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "align-middle" }, [
      _vm._v(_vm._s(_vm.$t(_vm.userType)))
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "align-middle" }, [
      _vm._v(_vm._s(_vm.report.profit_percent) + "%")
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "align-middle" }, [
      _c("div", { staticClass: "d-inline-flex small w-100" }, [
        _c("div", { staticClass: "position-relative d-flex" }, [
          _c("span", { staticClass: "d-inline-block w-50" }, [
            _vm._v(
              "\n          " + _vm._s(_vm.report.evangelist_sum) + "\n        "
            )
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "d-inline-block w-50" }, [
            _vm._v(
              "\n          " + _vm._s(_vm.report.producer_sum) + "\n        "
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "position-relative d-flex" }, [
          _c("span", { staticClass: "d-inline-block w-50" }, [
            _vm._v(
              "\n          " + _vm._s(_vm.report.content_ad_sum) + "\n        "
            )
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "d-inline-block w-50" }, [
            _vm._v(
              "\n          " + _vm._s(_vm.report.public_ad_sum) + "\n        "
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "align-middle" }, [
      _vm._v(_vm._s(_vm.report.total_sum))
    ]),
    _vm._v(" "),
    _c("td", { staticClass: "align-middle" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }